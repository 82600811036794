import { Avatar, Button, Separator, Switch } from '@s8/react-component-library';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import download_contact from '../../assets/DownloadContact.svg';
import download_contact_white from '../../assets/DownloadContactWhite.svg';
import calendar_ico from '../../assets/calendar-icon.svg';
import chat_ico from '../../assets/chat-ico.png';
import chat_white_ico from '../../assets/chat-white-ico.png';
import email_ico from '../../assets/email-ico.png';
import facebook_ico from '../../assets/facebook-ico.png';
import facebook_white_ico from '../../assets/facebook-white-ico.png';
import instagram_ico from '../../assets/instagram-ico.png';
import instagram_white_ico from '../../assets/instagram-white-ico.png';
import k_ico from '../../assets/k-ico.png';
import k_white_ico from '../../assets/k-white-ico.png';
import linkedin_ico_media from '../../assets/linkedin-ico-media.png';
import linkedin_white_ico from '../../assets/linkedin-white-ico.png';
import logo_s8 from '../../assets/logo-s8.png';
import logo from '../../assets/logo.png';
import phone_ico from '../../assets/phone-ico.png';
import x_ico from '../../assets/x-ico.png';
import x_white_ico from '../../assets/x-white-ico.png';
import youtube_ico from '../../assets/youtube-ico.png';
import youtube_white_ico from '../../assets/youtube-white-ico.png';
import AuthService from '../../services/AuthService';
import DataService from '../../services/DataService';
import VCardService from '../../services/VCardService';
import LeadGenerationForm from '../leadGenerationForm/leadGenerationForm';
import QRComponent from '../qrComponent/qrComponent';
import { Circle } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import './VCardProfileStyle.scss';
import HelperService from '../../services/HelperService';
import TeamsStatus from '../teamsStatus/teamsStatus';

export default function VCardProfile() {

    const navigate = useNavigate();

    let [isS8Style, setIsS8Style] = useState(false);
    let [isLoggedIn, setIsLoggedIn] = useState(false);

    let { email, theme } = useParams();
    let [ data, setData] = useState<any>(null);
    let [ photo, setPhoto ] = useState<any>(null);
    let [ presence, setPresence ] = useState<any>(null);
    const [ error, setError ] = useState<boolean>(false);

    const monthsAbr = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    useEffect(() => {
        email = email?.replace(/@synpulse\.com$/, '');
        localStorage?.getItem('theme') == 'dark' ? setIsS8Style(true) : setIsS8Style(false);
        getUserData();
        checkS8Style();
    },[email,theme]);


    function getUserData() {

        AuthService.getCurrentUser().then((response) => {
            if (response) {
              setIsLoggedIn(true);
              DataService.getInternal(email).then(
                (response) => {
                    setData(response);
                    DataService.getPresence(email).then(
                        (response) => {
                            setPresence(response);
                        }
                    )
                },
                (error) => {
                    setError(true);
                }
              )
            } else {
                DataService.getExternal(email).then(
                    (response) => {
                        setData(response);
                    },
                    (error) => {
                        setError(true);
                    }
                )
            }
        })

        DataService.getPhotoFromDB(email).then(
            (response) => {
                setPhoto(response);
            }
        )

        DataService.getPhoto(email).then(
            (response) => {
                setPhoto(response);
            }
        )
    }

    function logIn() {
        AuthService.logIn(window.location.pathname);
    }

    function checkS8Style() {
        if(theme == 'synpulse8') {
            setIsS8Style(true);
            localStorage.setItem('theme','dark');
        } else {
            setIsS8Style(false);
            localStorage.setItem('theme','light');
        }
    }

    function switchStyle() {
        if (theme === "synpulse") {
            navigate("/synpulse8/" + email);
            setIsS8Style(true);
            localStorage.setItem('theme','dark');
        } else {
            navigate("/synpulse/" + email);
            setIsS8Style(false);
            localStorage.setItem('theme','light');
        }
    }

    function downloadVCard() {
        data.photo = photo;
        VCardService.downloadContact(data);
    }

    function addDaySuffix(day: number): string {
        if (day >= 11 && day <= 13) {
          return `${day}th`;
        }

        switch (day % 10) {
          case 1:
            return `${day}st`;
          case 2:
            return `${day}nd`;
          case 3:
            return `${day}rd`;
          default:
            return `${day}th`;
        }
    }

    function backToDirectory() {
        navigate('/');
    }

    function handlePhoneClick(phoneNumber: string) {
        window.location.href = `tel:${phoneNumber}`;
    }

    function handleEmailClick(email: string) {
        window.location.href = `mailto:${email}`;
    }

    function handleCVClick() {
        window.open(data.linkToCV + "?web=1");
    }

    function handleBookingClick() {
        window.open(data.linkToBooking);
    }

    function getBirthday() {
        const [month, day] = data?.dateOfBirth.split('/');
        return monthsAbr[month - 1] + " " + addDaySuffix(+day);
    }

    if(error) {
        return(
            <div>
                <h4 className='text-center text-heading-4' style={{marginTop:'40vh'}}>Sorry, no business card found for the specified user.</h4>
            </div>
          )
    }

    if(!data) {
        return(
            <div>
                <h4 className='text-center text-heading-4' style={{marginTop:'40vh'}}>Loading...</h4>
            </div>
          )
    }

    return (
      <div style={{backgroundColor: isS8Style ? '#121A21': ''}}>
        {
            window.innerWidth > 800 ? <div className='container header-bar pb-3' style={{borderBottom: isS8Style ? 'solid 0.08rem #2F353A' : 'solid 0.08rem #E7E8E8'}}>
            <div>
            {isS8Style ? <img className='logo-img-s8' src={logo_s8}></img> : <img className='logo-img' src={logo}></img>}
            </div>
            {
                isLoggedIn ? <div className='login d-flex justify-content-end'>
                <Button
                    actionType="default"
                    onClick={() => backToDirectory()}
                    size="small"
                    variant="primary"
                    className={isS8Style ? 'btn-br-dark btn-header-action' : 'btn-header-action'}
                    >
                    Directory
                </Button>
            </div>  :  <div className='login d-flex justify-content-end'>
                <Button
                    actionType="default"
                    onClick={() => logIn()}
                    size="small"
                    variant="primary"
                    className={isS8Style ? 'btn-br-dark btn-header-action' : 'btn-header-action'}
                    >
                    Log In
                </Button>
            </div>
            }
        </div> : <div>
            {isLoggedIn ? <div className='login d-flex justify-content-end'>
                <Button
                    actionType="default"
                    onClick={() => backToDirectory()}
                    size="small"
                    variant="primary"
                    className={isS8Style ? 'btn-br-dark btn-header-action' : 'btn-header-action'}
                    >
                    Directory
                </Button>
            </div>  :  <div className='login d-flex justify-content-end'>
                <Button
                    actionType="default"
                    onClick={() => logIn()}
                    size="small"
                    variant="primary"
                    className={isS8Style ? 'btn-br-dark btn-header-action' : 'btn-header-action'}
                    >
                    Log In
                </Button>
            </div>}
        </div>
        }
        {
            window.innerWidth < 800 &&
            <div className='logo d-flex justify-content-center'>
                {isS8Style ? <img className='logo-img-internal' src={logo_s8}></img> : <img className='logo-img-internal' src={logo}></img>}
            </div>
        }
        <div className='profile-container profile-top-offset'>
            <div className='profile'>
                <div className='profile-info d-flex flex-column align-items-center'>
                    <div className='d-flex justify-content-center'>
                        {
                            photo ?  <div className='d-flex flex-column align-items-center img-container'>
                                <img className='vcard-avatar-img' src={`data:image/jpeg;base64,${photo}`}/>
                                {
                                    presence && <TeamsStatus presence={presence}/>
                                }
                            </div> : <div className='d-flex flex-column align-items-center img-container'>
                                <Avatar
                                    className='vcard-avatar-img'
                                    altText="NS"
                                    size="xlarge"
                                    type='image'
                                />
                                {
                                    presence && <TeamsStatus presence={presence}/>
                                }
                            </div>
                        }
                    </div>
                    <div>
                        <div className='profile-info-title text-center mt-3'><h4 className='profile-info-title-text text-heading-4' style={{color: isS8Style ? 'white' : ''}}>{data?.displayName}</h4></div>
                        <div className='profile-info-position text-center mt-1'>
                            <p className='profile-info-position-text'>{data?.jobTitle}</p>
                        </div>
                    </div>
                </div>
                <div className='profile-contacts profile-section  mt-5'>
                    <div className='profile-subtitle d-flex justify-content-between align-items-center'>
                        <h5 className='text-heading-5' style={{color: isS8Style ? 'white' : ''}}>Contact Info</h5>
                        <Button onClick={downloadVCard}>
                            <img className='arrow-right' src={isS8Style ? download_contact_white : download_contact}></img>
                        </Button>
                    </div>
                    <div className='profile-contacts-section'>
                        {
                            data.linkToBooking &&
                            <div className='book-meeting-container profile-contacts-email d-flex mt-4'>
                                <div className='email-ico pt-2 pb-2'>
                                    <img className='contact-ico' src={calendar_ico}></img>
                                </div>
                                <div className='ms-3 d-flex flex-column justify-content-between'>
                                    <div className='email-title subtitle'>
                                        <span>Calendar</span>
                                    </div>
                                    <div className='section-text' style={{color: isS8Style ? 'white' : 'black'}}>
                                        <Button
                                            actionType="default"
                                            onClick={handleBookingClick}
                                            size="small"
                                            variant="primary"
                                            className={isS8Style ? 'btn-cv-s8 mt-1': 'mt-1'}
                                        >
                                            Book a Meeting
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='profile-contacts-email d-flex mt-4'>
                            <div className='email-ico pt-2 pb-2'>
                                <img className='contact-ico' src={email_ico}></img>
                            </div>
                            <div className='ms-3 d-flex flex-column justify-content-between'>
                                <div className='email-title subtitle'>
                                    <span>Email & Teams</span>
                                </div>
                                <div className='section-text' style={{color: isS8Style ? 'white' : 'black'}}>
                                    <span className='clickable-data' onClick={() => handleEmailClick(data?.email)}>{data?.email}</span>
                                </div>
                            </div>
                        </div>
                        {
                            data?.phoneNumber && <div className='profile-contacts-phone d-flex mt-4'>
                                <div className='phone-ico pt-2 pb-2'>
                                    <img className='contact-ico' src={phone_ico}></img>
                                </div>
                                <div className='ms-3 d-flex flex-column justify-content-between'>
                                    <div className='phone-title subtitle'>
                                        <span>Contact Number</span>
                                    </div>
                                    <div className='section-text' style={{color: isS8Style ? 'white' : 'black'}}>
                                        <span className='clickable-data' onClick={() => handlePhoneClick(data?.phoneNumber)}>{data?.phoneNumber}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className="w-316  mt-4">
                            <Separator type="subtle"/>
                        </div>
                    </div>
                </div>
                {
                    isLoggedIn && <div className='profile-employee profile-section  mt-5'>
                    <div className='profile-subtitle d-flex justify-content-between align-items-center'>
                        <h5 className='text-heading-5' style={{color: isS8Style ? 'white' : ''}}>Employee Info</h5>
                    </div>
                    <div className='employee-info-container'>
                            <div className='employee-id subsection-i'>
                                <div className='subtitle'>
                                    ID
                                </div>
                                <div className='employee-id-text' style={{color: isS8Style ? 'white' : ''}}>
                                    {data?.employeeId}
                                </div>
                            </div>
                            <div className='employee-position subsection-i '>
                                <div className='subtitle'>
                                    Position
                                </div>
                                <div className='position-text' style={{color: isS8Style ? 'white' : ''}}>
                                    <span>{data?.jobPosition}</span> (<span>{data?.jobLevel})</span>
                                </div>
                            </div>
                            <div className='employee-department subsection-i '>
                                <div className='subtitle'>
                                    Department
                                </div>
                                <div className='department-text' style={{color: isS8Style ? 'white' : ''}}>
                                    <span>{data?.department}</span>
                                </div>
                            </div>
                            <div className='employee-vertical subsection-i '>
                                <div className='subtitle'>
                                    Vertical
                                </div>
                                <div className='vertical-text' style={{color: isS8Style ? 'white' : ''}}>
                                    <span>{data?.vertical}</span>
                                </div>
                            </div>
                            {
                                data?.focusRole != 'N/A' && <div className='employee-focus-role subsection-i '>
                                    <div className='subtitle'>
                                        Focus Role
                                    </div>
                                    <div className='focus-role-text' style={{color: isS8Style ? 'white' : ''}}>
                                        <span>{data?.focusRole}</span>
                                    </div>
                                </div>
                            }
                            {
                                data.linkToCV && <div className='employee-cv subsection-i'>
                                    <div className='subtitle'>
                                        Employee CV
                                    </div>
                                    <div>
                                    <Button
                                        actionType="default"
                                        onClick={handleCVClick}
                                        size="small"
                                        variant="primary"
                                        className={isS8Style ? 'btn-cv-s8 mt-1': 'mt-1'}
                                    >
                                        View CV
                                    </Button>
                                    </div>
                                </div>
                            }
                            <div className='country subsection-i '>
                                <div className='subtitle'>
                                    Country
                                </div>
                                <div className='country-text' style={{color: isS8Style ? 'white' : ''}}>
                                    {data?.country}
                                </div>
                            </div>
                            <div className='location-id subsection-i '>
                                <div className='subtitle'>
                                    Location ID
                                </div>
                                <div className='country-text' style={{color: isS8Style ? 'white' : ''}}>
                                    {data?.locationId}
                                </div>
                            </div>
                            <div className='date-of-hire subsection-i '>
                                <div className='subtitle'>
                                    Date of Hire
                                </div>
                                <div className='date-of-hire-text' style={{color: isS8Style ? 'white' : ''}}>
                                    {monthsAbr[new Date(data?.dateOfHire).getMonth()]} {addDaySuffix(new Date(data?.dateOfHire).getDate())}, {new Date(data?.dateOfHire).getFullYear()}
                                </div>
                            </div>
                            {
                                data.dateOfBirth && <div className='date-of-birth subsection-i '>
                                    <div className='subtitle'>
                                        Date of Birth
                                    </div>
                                    <div className='date-of-birth-text' style={{color: isS8Style ? 'white' : ''}}>
                                        {getBirthday()}
                                    </div>
                                </div>
                            }
                            {data?.focusRole == 'N/A' && <div className='subsection-i'></div>}
                            <div className='employee-cv subsection-i'></div>
                    </div>
                    <div>
                        <div className="w-316  mt-4">
                            <Separator type="subtle" />
                        </div>
                    </div>
                </div>
                }
                <div className='profile-company profile-section mt-4'>
                    <div className='profile-subtitle d-flex justify-content-between align-items-center'>
                        <h5 className='text-heading-5' style={{color: isS8Style ? 'white' : ''}}>Company Info</h5>
                    </div>
                    <div className='company-info'>
                        <div className='entity mt-4 company-info-section'>
                            <div className='subtitle'>
                                <span>Entity</span>
                            </div>
                            <div className='address mt-1' style={{color: isS8Style ? 'white' : ''}}>
                                <div style={{whiteSpace:'nowrap'}}>{data?.entityName[0]}</div>
                                <div>{data?.entityName[1]}</div>
                                <div style={{whiteSpace:'nowrap'}}>{data?.entityName[2]} {data?.entityName[3]}</div>
                            </div>
                        </div>
                        <div className='website company-info-section mt-4' style={{color: isS8Style ? 'white' : ''}}>
                            <div className='subtitle'>
                                <span >Website</span>
                            </div>
                            <div className='website mt-1 section-text'>
                                <a href={isS8Style ? "https://synpulse8.com/" : "https://synpulse.com/"} className='section-text clickable-data' style={{color: isS8Style ? 'white' : 'black'}}>{isS8Style ? 'www.synpulse8.com' : 'www.synpulse.com'}</a>
                            </div>
                        </div>
                        <div className='social-media company-info-section mt-4'>
                            <div className='subtitle'>
                                Social Media
                            </div>
                            <div className='social-media-list d-flex justify-content-between mt-2'>
                                <div className='clickable-data'>
                                    <a href='https://twitter.com/synpulsemc?lang=en' target='_blank'>
                                        <img  src={isS8Style ? x_white_ico : x_ico}></img>
                                    </a>
                                </div>
                                <div className='clickable-data'>
                                    <a href='https://www.facebook.com/synpulsemc/' target='_blank'>
                                        <img  src={isS8Style ? facebook_white_ico : facebook_ico}></img>
                                    </a>
                                </div>
                                <div className='clickable-data'>
                                    <a href='https://www.instagram.com/lifeatsynpulse/' target='_blank'>
                                        <img  src={isS8Style ? instagram_white_ico : instagram_ico}></img>
                                    </a>
                                </div>
                                <div className='clickable-data'>
                                    <a href={isS8Style ? 'https://www.linkedin.com/company/synpulse8/': 'https://www.linkedin.com/company/synpulsemc/mycompany/'} target='_blank'>
                                        <img  src={isS8Style ? linkedin_white_ico : linkedin_ico_media}></img>
                                    </a>
                                </div>
                                <div className='clickable-data'>
                                    <a href='http://www.youtube.com/@SynpulseGroup' target='_blank'>
                                        <img  src={isS8Style ? youtube_white_ico : youtube_ico}></img>
                                    </a>
                                </div>
                                <div className='clickable-data'>
                                    <a href='https://storage.pardot.com/584733/1680604228iBzBTN6a/20230330_WeChat_landing_page.jpg' target='_blank'>
                                        <img  src={isS8Style ? chat_white_ico : chat_ico}></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='qr-code-wrapper profile-section'>
                    <div className='qr-code'>
                        <QRComponent
                            value={window.location.href}
                            name={data.displayName}
                            style={theme ? theme : "synpulse"}
                            width={200}
                            height={200}
                            isLoggedIn={isLoggedIn}
                        />
                        {
                            isLoggedIn && <div className='style-switch d-flex justify-content-center mt-5'>
                                <div className='ms-3' style={{color: isS8Style ? 'white' : ''}}>Synpulse</div>
                                <div className='ms-3'>
                                <Switch
                                        id="switch"
                                        onClick={() => switchStyle()}
                                        size="medium"
                                        enabled={isS8Style}
                                />
                                </div>
                                <div className='ms-3' style={{color: isS8Style ? 'white' : ''}}>Synpulse 8</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {!isLoggedIn && <LeadGenerationForm isS8Style={isS8Style}/>}
            <div className='spacer'></div>
        </div>
      </div>
    )
}
