import { Avatar } from '@s8/react-component-library';
import React, { Component, useEffect, useState } from 'react'
import DataService from '../../services/DataService';
import CircleIcon from '@mui/icons-material/Circle';
import { Circle } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import HelperService from '../../services/HelperService';
import './DirectoryStyle.scss';
import TeamsStatus from '../teamsStatus/teamsStatus';

const EmployeeListItem = ({data,isS8Style}:any) => {

    let [ photo, setPhoto ] = useState<any>(null);
    let [ presence, setPresence ] = useState<any>(null);

    const selectUser =  () => {
      const url = isS8Style ? `/synpulse8/${data.id}` : `/synpulse/${data.id}@synpulse.com`
      window.open(url, '_blank');
    };

    useEffect(() => {
      DataService.getPhotoDirectory(data.email).then(
        (response) => {
            setPhoto(response);
      });

      DataService.getPresence(data.id).then(
        (response) => {
            setPresence(response);
        }
    )
    },[data.email]);


    return (
      <>
        {
          window.innerWidth > 800
          ?
          <button className='employee-list-item' style={{backgroundColor: isS8Style ? '#2F353A' : '#F3F3F4'}} onClick={() => selectUser()}>
          <div className='image-container ms-2'>
            {
              photo ? <div>
                    <img className='list-item-avatar' src={`data:image/jpeg;base64,${photo}`}/>
                    {
                      presence && <TeamsStatus presence={presence} class='directory-circle-status'/>
                    }
                  </div>
                  :
                  <div className=''>
                      <Avatar
                      className='list-item-avatar'
                      altText={(data?.givenName && data?.surname) ? `${data?.givenName[0]}${data?.surname[0]}` : ``}
                      type='text'
                      size='xlarge'
                      />
                    {
                      presence && <TeamsStatus presence={presence} class='directory-circle-status'/>
                    }
                  </div>
            }
          </div>
          <div className='list-item-description'>
            <div className='list-item-name'>
              <span className='item-title'>{data?.displayName}</span> | <span className='item-title'>{data?.employeeId}</span>
            </div>
            <div className='list-item-info' style={{color: isS8Style ? 'white' : 'black'}}>
              <div className='list-item-data'>
                <div className='list-item-info-data'>{data?.jobTitle}</div>
                <div className='list-item-info-data'>{data?.email}</div>
                <div className='list-item-info-data'>{data?.phoneNumber}</div>
              </div>
              <div className='list-item-data'>
                <div className='list-item-info-data'><span>{data?.shortVertical}</span> | <span>{data?.department}</span></div>
                <div className='list-item-info-data'><span>{data?.jobPosition}</span> (<span>{data?.jobLevel})</span></div>
                { data?.focusRole != ('N/A') &&
                <div>
                  <div className='list-item-info-data'>{data?.focusRole}</div>
                </div>
                }
              </div>
              <div className='list-item-data'>
                <div className='list-item-info-data'><span>{data?.locationId}</span></div>
                <div className='list-item-info-data'><span>{data?.officeLocation}</span></div>
                <div className='list-item-info-data'><span>{data?.locationName}</span></div>
              </div>
            </div>
          </div>
        </button>
          :
        <button className='btn employee-list-item-mobile d-flex' style={{backgroundColor: isS8Style ? '#2F353A' : '#F3F3F4'}} onClick={() => selectUser()}>
          <div className='image-container ms-1'>
            {
              photo ? <div>
                  <img className='list-item-avatar-mobile' src={`data:image/jpeg;base64,${photo}`}/>
                  {
                    presence && <TeamsStatus presence={presence}/>
                  }
                </div>
                :
                <div>
                    <Avatar
                  className='list-item-avatar-mobile mt-0'
                  altText={(data?.givenName && data?.surname) ? `${data?.givenName[0]}${data?.surname[0]}` : ``}
                  type='text'
                />
                  {
                    presence && <TeamsStatus presence={presence}/>
                  }
                </div>
            }
          </div>
          <div className='ms-3 mt-1 text-start' style={{color: isS8Style ? 'white' : 'black'}}>
              <div className='list-item-name'>
                <span className='item-title-mobile' style={{}}>{data?.displayName}</span>
              </div>
              <div>
                <span className='item-text-mobile'>{data?.jobPosition}</span>
              </div>
          </div>
        </button>
        }
      </>
    )
  }

export default EmployeeListItem;
