import { useCallback, useEffect, useRef, useState } from "react";
import EmployeeListItem from "./EmployeeListItem";
import DataService from "../../services/DataService";
import { CircularProgress } from '@mui/material';

const EmployeeList = ({employees, isS8Style}:any) => {

    const [allEmployees, setallEmployees] = useState(employees || []);
    const [isLoading, setIsLoading] = useState(false);
    const [visibleItems, setVisibleItems] = useState(10);

    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight + 5) ||
        window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight - 5) &&
        !isLoading
      )  {
          if(visibleItems < allEmployees.length) {
            setIsLoading(true);
            setTimeout(() => {
              setVisibleItems((prev) => Math.min(prev + 10, allEmployees.length));
              setIsLoading(false);
            }, 500);
          }
        }
    };

    useEffect(() => {
      setallEmployees(employees || []);
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [employees, visibleItems, allEmployees.length, isLoading]);

    return (
      <div className='employee-list'>
          {
            allEmployees.slice(0,visibleItems).map((employee:any,index: any) => {
              return  <EmployeeListItem data={employee} key={index} isS8Style={isS8Style}/>
            })
          }
        <div>
          {
            isLoading &&
            <div className='w-100 d-flex justify-content-center mt-3'>
              <CircularProgress style={{color:isS8Style ? 'white' : 'black'}}/>
            </div>
          }
        </div>
      </div>
    )
  }

  export default EmployeeList;